<template>
    <div class="container">
        <div class="search-attr">
            <div>
                <a-select size='small' style="margin:5px;" v-model="params.timeType" placeholder='请选择'>
                    <a-select-option v-for="item of time_list" :key="item.value">{{item.name}}</a-select-option>
                </a-select>
                <a-range-picker :placeholder='["开始时间","结束时间"]' size='small' style="margin:5px;" @change="onChange" />
                <a-select :dropdownMatchSelectWidth="false" @select="changeVersionlist" allowClear showSearch size='small' style="margin:5px;width:150px" v-model="params.kindId" placeholder='请选择大币种'>
                    <a-select-option v-for="items of bigList" :key="items.kindId" :value='items.kindId'>{{items.kindName}}</a-select-option>
                </a-select>
                <a-select :dropdownMatchSelectWidth="false" @select="selectVersion" allowClear @search='getVersitionList' showSearch :filter-option="untils.filterOption"  size='small' style="margin:5px;width:150px" v-model="params.versionId" placeholder='请选择版别'>
                    <a-select-option v-for="items of smallList" :key="items.versionId" :value='items.versionId'>{{items.versionName}}</a-select-option>
                </a-select>
                <a-select :dropdownMatchSelectWidth="false" size='small' style="margin:5px;" v-model="params.goodsType">
                    <a-select-option v-for="item of type_list" :key="item.value">{{item.name}}</a-select-option>
                </a-select>
                <a-select :dropdownMatchSelectWidth="false" size='small' mode="multiple" style="margin:5px;min-width:150px" v-model="params.scores" placeholder='请选择评分(可多选)'>
                    <a-select-option v-for="item of ranks" :key="item.id" :value='item.id'>{{item.scoreName}}</a-select-option>
                </a-select>
                <a-button @click="getChartsDataForLine" size='small' style="margin-left:10px;">搜索</a-button>

                <a-button @click="clearCache(params.versionId)" v-if="params.versionId" type='primary' size='small' style="margin-left:10px;">清缓存</a-button>
                <a-button @click="calcLine(params.versionId)" v-if="params.versionId" type='primary' size='small' style="margin-left:10px;">曲线计算</a-button>
            </div>
        </div>
        <div id="myChart" :style="{width: '100%',flex:'1'}"></div>
        <div style="width:100%;height:200px;display:flex;flex-direction:column;">
            <h4>价格节点关联数据</h4>
            <div class="bottom-line"></div>

            <div class="sq-box">
                <div class="sq-item" v-for="(item,index) of sqData" :key="index">
                    <div>{{item.goodsName}}</div>
                    <div>{{item.goodsType == 1 ? 'PCGS' : '公博'}}</div>
                    <div>{{item.pcgsCode}}</div>
                    <div>{{item.scoreName}}</div>
                    <div>{{item.goodsPrice}}元</div>
                    <div>{{item.tradeTime}}</div>
                    <div @click="openModal(item.spliderId)"><a>{{item.spliderName}}</a></div>
                    <div>{{item.adminName}}</div>
                    <div><a @click="look_detail(item)">操作</a></div>
                </div>
            </div>
        </div>

        <a-modal v-model="visible" title="查看详情" @cancel='resetModal' @ok='visible = false'>
            <a-form v-bind="layout">
                <a-form-item label='商品名称'>{{modalData.title}}</a-form-item>
                <a-form-item label='评级公司'>{{modalData.companyType == 1 ? 'PCGS' : '公博'}}</a-form-item>
                <a-form-item label='评级分数'>{{modalData.pcgsScore||'-'}}</a-form-item>
                <a-form-item label='金额'>{{modalData.price}}元</a-form-item>
                <a-form-item label='成交时间'>{{modalData.tradeTime||'-'}}</a-form-item>
            </a-form>
        </a-modal>

        <a-modal v-model="showEditModal" title="修改白币价格" @cancel='resetChartsModal' @ok='editChartsData'>
            <a-form v-bind="layout">
                <a-form-item label='银元名称'>
                    {{editModalProxy.title}}
                </a-form-item>
                <a-form-item label='评级分数'>
                    {{editModalProxy.score}}
                </a-form-item>
                <a-form-item label='成交时间'>
                    {{editModalProxy.time}}
                </a-form-item>
                <a-form-item label='成交价格'>
                    <div style="display:Flex;align-items:center;">
                        <a-input style="flex:1;margin-right:5px;" v-model="editModalProxy.price" placeholder='请输入价格'></a-input>
                        <a-checkbox :checked='editModalProxy.checked' @change="changeCheck">
                            锁定 {{editModalProxy.time}} 当天价格
                        </a-checkbox>
                    </div>
                    
                </a-form-item>
                <a-form-item label='调整范围'>
                    <div style="display:Flex;align-items:center;">
                        <a-input style="flex:1;margin-right:5px;" v-model="editModalProxy.range"></a-input>
                        <span>(向后影响天数)</span>
                    </div>
                </a-form-item>
            </a-form>
        </a-modal>
    </div>
</template>

<script>
let echarts = require('echarts/lib/echarts')
// 引入柱状图组件
require('echarts/lib/chart/bar')
// 引入提示框和title组件
require('echarts/lib/component/tooltip')
require('echarts/lib/component/title')
export default {
    data(){
        return {
           params:{
               timeType:3,
               goodsType:2,
               startTime:'',
               endTime:'',
               scores:[],
           },
           time_list:[
               {name:'近七天',value:1},
               {name:'近半月',value:2},
               {name:'近一月',value:3},
               {name:'近三月',value:4},
               {name:'近半年',value:5},
               {name:'近一年',value:6},
           ],
            type_list:[
                {name:'PCGS',value:1},
                {name:'公博',value:2},
            ],
            ranks:[],
            bigList:[],
            smallList:[],
            chartsEvent:null,
            chartsTimes:[],
            chartsData:{},
            sqData: [],
            visible:false,
            modalData:{},
            layout:{
                labelCol: { span: 4 },//标签占5份  共24份
                wrapperCol: { span:20 },
            },
            showEditModal:false,
            
            chartsScoreId:null,
           
            editModalProxy:{
                modalEditTime:'',
                modalEditName:'',
                editPrice:'',
                checked:true,
            }
        }
    },
    created(){
        this.getSelectList();
        this.getChartsDataForLine();
    },
    mounted() {
        addEventListener('resize',e=>{
           try{
                this.chartsEvent.resize();
           }catch(e){

           }
        })
    },
    methods:{
        // 计算曲线
        async calcLine(id){
            const res = await this.axios('/dq_admin/coinSilverVersionGoods/initData',{params:{
                startTime: "2021-03-01",
                versionId: id,
                scoreId: ""
            }});
            if(res.status != 200) return
            this.$message.success("操作成功,请在两分钟之后查看!")
        },
        onChange(array){
            if(array&&array.length>0){
                array.forEach((ele,index) => {
                    if(index == 0){
                        this.params.startTime = ele._d.toLocaleDateString().replace(/\//g,"-")
                    }else{
                        this.params.endTime = ele._d.toLocaleDateString().replace(/\//g,"-")
                    }
                });
            }else{
                this.params.startTime='';
                this.params.endTime = '';
            }
        },
        async getChartsItemInfo(id){
            const res = await this.axios('/dq_admin/coinSilverVersionGoods/getChartsItemInfo',{
                params:{statisticsId:id,goodsType:this.params.goodsType}
            })
            if(res.status == 200){
                this.editModalProxy = res.data;
            }
        },
        async changeVersionlist(){
            this.params.versionId = null;
            this.params.scores = [];
            this.getVersitionList('');
        },
        async getSelectList(){
            const res = await this.axios('/dq_admin/coinSilverVersionGoods/getSliverKind');
            if(res.status == 200){
                const {data} = res;
                this.bigList = data;
            }
        },
        async getVersitionList(str){
            const res = await this.axios('/dq_admin/coinSilverVersionGoods/getSliverVersion',{
                params:{keyword:str,kindId:this.params.kindId}
            });
            if(res.status == 200){
                const {data} = res;
                this.smallList = data;
            }
        },
        async getChartsDataForLine(){
            const postData = JSON.parse(JSON.stringify(this.params));
            postData.scores = postData.scores.join(',')
            const res = await this.axios('/dq_admin/coinSilverVersionGoods/getLineChart',{
                params:postData
            });
            try{
                if(res.status == 200){
                    const {data} = res;
                    const firtsData = data[0].statisticsList;
                    this.chartsTimes = firtsData.map((row)=>row.tradeTime)
                    this.chartsData = data.map((ele)=>{
                        const list = ele.statisticsList.map((row)=>{
                            return {
                                value:row.price,id:row.id
                            }
                        });
                        let name = ele.title;
                        let type ='line';
                        const obj = {
                            name,type,data:list,smooth:true,//折点是圆弧状的
                            showSymbol: true,
                            // symbol: 'circle',     //折点设定为实心点
                            symbolSize: 15,   //设定实心点的大小
                        }
                        return obj
                    })
                    if(this.chartsEvent!=null){
                        this.chartsEvent.dispose();
                    }
                    
                    this.drawLine();
                    this.$forceUpdate();
                    

                }
            }catch(e){

            }
            
        },
        drawLine() {
            // 基于准备好的dom，初始化echarts实例
            let chartsEvent = echarts.init(document.getElementById('myChart'))
            this.chartsEvent = chartsEvent;
            let options = {
                tooltip: {
                    trigger: 'axis',
                    // formatter:function(params) {
                    //     var result = params[0].axisValue + "<br/>"
                    //     params.forEach(function (item) {
                    //         result += item.marker + item.seriesName + "：" + item.data.value + "元" +"<br/>"
                    //     })
                    //     return result
                    //   }
                    formatter: function (params) {
                        var result = params[0].axisValue + "<br/>"
                        let newParams = [];
                        let tooltipString = [];
                        newParams = [...params];
                        newParams.sort((a,b) => {return b.value - a.value});
                        newParams.forEach((p) => {
                            const cont = p.marker + ' ' + p.seriesName + ' : ' + p.value + "元" + '<br/>';
                            tooltipString.push(cont);
                        });
                        return result+tooltipString.join('');
                    }
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                toolbox: {
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data:this.chartsTimes
                },
                yAxis: {
                    type: 'value'
                },
                series:this.chartsData
            }
            chartsEvent.setOption(options);
            this.chartsEvent.on('click',(e)=>{
                const {id} = e.data;
                this.getListData(id)
            });
            this.chartsEvent.on('contextmenu',(e)=>{
                e.event.event.preventDefault();
                const {id} = e.data;
                this.getChartsItemInfo(id)
                this.chartsScoreId = id;
                this.openChartsModal();
            })
        },
        async getListData(id){
            const res = await this.axios('/dq_admin/coinSilverVersionGoods/getVersionGoodsByStatisticsId',{params:{
                statisticsId:id,goodsType:this.params.goodsType}})
            try{
                if(res.status == 200){
                    const {data} = res;
                    this.sqData = data;
                }
            }catch(e){

            }
            
        },
        openModal(spliderId){
            this.visible = true;
            this.getDetailData(spliderId);
        },

        async getDetailData(spliderId){
            const res = await this.axios('/dq_admin/coinSilverVersionGoodsSplider/getCoinSilverVersionGoodsSpliderById',{params:{spliderId}})
            if(res.status == 200){
                const {data} = res;
                this.modalData = data;
            }
        },
        resetModal(){
            this.modalData = {}
        },
        selectVersion(id){
            this.params.scores = []
            this.getRateListForVersionId(id)
        },
        async getRateListForVersionId(versionId){
            const res = await this.axios('/dq_admin/coinSilverVersionGoods/getKindVersionAndScoreByPcgsCodeOrVersionId',{
                params:{versionId}
            })
            if(res.status == 200){
                const {scoreList} = res.data; 
                this.ranks = scoreList;
            }
        },
        async editChartsData(){
            const params = JSON.parse(JSON.stringify(this.editModalProxy))
            params.checked = params.checked == true ? 1 : 0;
            const res = await this.axios.post('/dq_admin/coinSilverVersionGoods/edit/scoreIdForPrice',params)
            if(res.status == 200){
                this.$message.success(res.message||'请求成功');
                this.getChartsDataForLine();
                this.chartsScoreId = null;
                this.showEditModal = false;
            }
        },
        openChartsModal(){
            this.showEditModal = true;
        },
        resetChartsModal(){
            this.editModalProxy = {};
            this.chartsScoreId = null;
        },
        changeCheck(e){
            const {checked} = e.target
            this.editModalProxy.checked = checked;
        },
        look_detail(row){
            const {id} = row
            const routeData = this.$router.resolve({
                path: "/cms/currencymannage/tradingmanage",
                query: {
                    id:id
                }
            });
            window.open(routeData.href, '_blank');
        },
        async clearCache(params){
            const res = await this.axios('/dq_admin/kinditemversion/clearcacheItemVersionById',{params:{id:params}})
            if(res.status == 200){
                this.$message.success(res.message)
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.container{
    width:100%;height:100%;display: flex;flex-direction: column;
    .bottom-line{height:1px;background:black;width:100%;}
    .sq-box{width:100%;overflow-y: auto;height:100%;flex:1;overflow-y:auto;
        .sq-item{display:flex;justify-content:space-between;padding:20px 0 20px 0;
            div{width:11.1%;text-align: center;}
        }
    }
}
.search-attr{display: flex;justify-content: space-between;}
.content{display: flex;flex-direction: column;
    .table-content{
        flex:1;
        overflow-y: auto;
    }
}
</style>